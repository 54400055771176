*, ::before, ::after{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop{
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness:proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container{
  width:100%;
}

@media (min-width: 640px){
  .container{
    max-width:640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width:768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width:1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width:1280px;
  }
}

@media (min-width: 1536px){
  .container{
    max-width:1536px;
  }
}

.visible{
  visibility:visible;
}

.\!visible{
  visibility:visible !important;
}

.fixed{
  position:fixed;
}

.relative{
  position:relative;
}

.m-25{
  margin:25px;
}

.mt-10{
  margin-top:10px;
}

.block{
  display:block;
}

.flex{
  display:flex;
}

.list-item{
  display:list-item;
}

.h-screen{
  height:100vh;
}

.flex-grow{
  flex-grow:1;
}

.items-center{
  align-items:center;
}

.justify-center{
  justify-content:center;
}

.gap-1{
  gap:4px;
}

.truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.rounded{
  border-radius:4px;
}

.rounded-l-md{
  border-top-left-radius:6px;
  border-bottom-left-radius:6px;
}

.rounded-r-md{
  border-top-right-radius:6px;
  border-bottom-right-radius:6px;
}

.border{
  border-width:1px;
}

.border-gray-300{
  --tw-border-opacity:1;
  border-color:rgb(209 213 219 / var(--tw-border-opacity));
}

.bg-blue-500{
  --tw-bg-opacity:1;
  background-color:rgb(59 130 246 / var(--tw-bg-opacity));
}

.px-4{
  padding-left:4px;
  padding-right:4px;
}

.py-2{
  padding-top:2px;
  padding-bottom:2px;
}

.text-center{
  text-align:center;
}

.text-lg{
  font-size:16px;
  line-height:18px;
}

.text-white{
  --tw-text-opacity:1;
  color:rgb(255 255 255 / var(--tw-text-opacity));
}

html{
  font-size:16px;
}

@media (prefers-color-scheme: dark) {
  body{
    background:#000000;
  }
}

@media (prefers-color-scheme: light) {
  body{
    background:#ffffff;
  }
}

body{
  margin:0;
  padding:0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

:root:root{
  --adm-color-primary: #5D61FF;
  --fixed-active-line-width: 9px;
}

div{
  line-height:1
}

p{
  margin: 0;
  padding: 0;
}

.color-primary{
  color:var(--theme-color)
}

.swap-title{
  color: var(--adm-color-primary);
  font-size: 24px;
  font-weight: bold;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

@media screen and (max-width:767px){
  .swap-title{
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  body {
    display: flex;
    justify-content: center;
  }
}

.px-10{
  padding-left:10px;
  padding-right:10px;
}

.py-10{
  padding-top:10px;
  padding-bottom:10px;
}

.pt-10{
  padding-top:10px;
}

.adm-tabs-tab.adm-tabs-tab-active{
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.hover\:bg-blue-600:hover{
  --tw-bg-opacity:1;
  background-color:rgb(37 99 235 / var(--tw-bg-opacity));
}

.focus\:border-blue-500:focus{
  --tw-border-opacity:1;
  border-color:rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:outline-none:focus{
  outline:2px solid transparent;
  outline-offset:2px;
}

.focus\:ring:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-200:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgb(191 219 254 / var(--tw-ring-opacity));
}